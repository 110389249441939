import { FC } from 'react';

const BurgerMenuIcon: FC = () => {
  return (
    <svg width="20" height="11" viewBox="0 0 20 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line y1="0.5" x2="20" y2="0.5" stroke="white"/>
      <line y1="10.5" x2="20" y2="10.5" stroke="white"/>
      <line y1="5.5" x2="7" y2="5.5" stroke="white"/>
      <line x1="13" y1="5.5" x2="20" y2="5.5" stroke="white"/>
      <line x1="9" y1="5.5" x2="11" y2="5.5" stroke="white"/>
    </svg>
  );
}

export default BurgerMenuIcon;
