import { Margins } from 'components/shared/types';
import { getMarginFromProps } from 'components/shared/utils';
import { ImageProps } from 'next/image';
import { FC, createElement, lazy } from 'react';

import cx from 'classnames';

const Image = lazy(() => import('next/image'));

import styles from './Container.module.scss';

type ContainerProps = {
  as?: keyof JSX.IntrinsicElements;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  ImageProps?: ImageProps & { alt: string };
} & Margins &
  React.HTMLAttributes<HTMLElement>;

const Container: FC<ContainerProps> = ({
  as = 'div',
  style,
  children,
  ImageProps,
  ...rest
}) => {
  const marginStyle = getMarginFromProps(rest);

  if (ImageProps) {
    const className = cx(styles.heroContainer, rest.className);

    return (
      <div className={className} style={{ ...marginStyle, ...style }} {...rest}>
        <Image {...ImageProps} alt={ImageProps.alt || ''} />

        <div className={styles.heroContent}>{children}</div>
      </div>
    );
  }

  return createElement(
    as,
    { style: { ...marginStyle, ...style }, ...rest },
    children
  );
};

export default Container;
