export const colors = {
  black: '#191919',
  white: '#ffffff',
  gold: {
    dark: '#BAA57E',
    medium: '#E8DECA',
    light: '#F9F7F3',
  },
  grey: {
    dark: '#505050',
    medium: '#b2b2b2',
    light: '#f6f6f6',
  },
  forms: {
    red: '#B71610',
    green: '#40AD29',
  },
};
