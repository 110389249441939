import { FC, useContext, Suspense } from 'react';
import dynamic from 'next/dynamic';

import {
  DialogueContext,
  DialogueState,
} from 'customHooks/useDialogue/useDialogue';

import {
  SIGN_IN_DIALOG,
  FORGOT_PASSWORD_DIALOGUE,
  RESET_PASSWORD_DIALOGUE,
  CHECK_YOUR_EMAIL_DIALOGUE,
  JOIN_US_DIALOG_STEP_1,
  JOIN_US_DIALOG_STEP_2,
  JOIN_US_DIALOG_STEP_3,
  MOBILE_PARTNER_MENU_DIALOGUE,
  MOBILE_MENU_DIALOGUE,
  TERMS_AND_CONDITIONS_DIALOGUE,
  NEWSLETTER_SUBSCRIPTION_MODAL,
  COOKIE_CONSENT_MODAL,
  REGISTRATION_SUCCESS_DIALOGUE,
} from 'data/constants';
import {
  CurrencyType,
  MembershipType,
  DialogueType,
  CountryCodeType,
} from 'types/types';

const SignInDialogue = dynamic(
  () => import('blocks/DialoguePresenter/dialogs/SignInDialogue'),
  { suspense: true }
);
const MobileMenuDialoguePartner = dynamic(
  () => import('blocks/DialoguePresenter/dialogs/MobileMenuDialoguePartner'),
  { suspense: true }
);
const MobileMenuDialogue = dynamic(
  () => import('blocks/DialoguePresenter/dialogs/MobileMenuDialogue'),
  { suspense: true }
);
const ForgotPasswordDialogue = dynamic(
  () => import('blocks/DialoguePresenter/dialogs/ForgotPasswordDialogue'),
  { suspense: true }
);
const CheckYourEmailDialogue = dynamic(
  () => import('blocks/DialoguePresenter/dialogs/CheckYourEmailDialogue'),
  { suspense: true }
);
const JoinUsStep1Dialogue = dynamic(
  () => import('blocks/DialoguePresenter/dialogs/JoinUsStep1Dialogue'),
  { suspense: true }
);
const JoinUsStep2Dialogue = dynamic(
  () => import('blocks/DialoguePresenter/dialogs/JoinUsStep2Dialogue'),
  { suspense: true }
);
const JoinUsStep3Dialogue = dynamic(
  () => import('blocks/DialoguePresenter/dialogs/JoinUsStep3Dialogue'),
  { suspense: true }
);
const TermsAndConditionsDialogue = dynamic(
  () => import('blocks/DialoguePresenter/dialogs/TermsAndConditionsDialogue'),
  { suspense: true }
);
const CookieConsentBanner = dynamic(
  () => import('blocks/DialoguePresenter/dialogs/cookies/CookieConsentBanner'),
  { suspense: true }
);
const NewsLetterSubscriptionModal = dynamic(
  () => import('blocks/DialoguePresenter/dialogs/NewsLetterSubscriptionModal'),
  { suspense: true }
);
const RegistrationSuccessDialogue = dynamic(
  () => import('blocks/DialoguePresenter/dialogs/RegistrationSuccessDialogue'),
  { suspense: true }
);
const ResetPasswordDialogue = dynamic(
  () => import('blocks/DialoguePresenter/dialogs/ResetPasswordDialogue'),
  { suspense: true }
);

interface IDialoguePresenter {
  memberships: MembershipType[];
  currencies: CurrencyType[];
  countryCodes: CountryCodeType[];
}

const renderDialogue = (
  dialogueState: DialogueState,
  openDialogue: (dialogueType: DialogueType) => void,
  closeDialogue: () => void,
  memberships: MembershipType[],
  currencies: CurrencyType[],
  countryCodes: CountryCodeType[]
) => {
  switch (dialogueState.presentedDialogueType) {
    case SIGN_IN_DIALOG:
      return <SignInDialogue handleClose={closeDialogue} />;
    case FORGOT_PASSWORD_DIALOGUE:
      return <ForgotPasswordDialogue handleClose={closeDialogue} />;
    case CHECK_YOUR_EMAIL_DIALOGUE:
      return <CheckYourEmailDialogue handleClose={closeDialogue} />;
    case JOIN_US_DIALOG_STEP_1:
      return (
        <JoinUsStep1Dialogue
          handleClose={closeDialogue}
          memberships={memberships}
          currencies={currencies}
        />
      );
    case JOIN_US_DIALOG_STEP_2:
      return (
        <JoinUsStep2Dialogue
          handleClose={closeDialogue}
          countryCodes={countryCodes}
          currencies={currencies}
        />
      );
    case JOIN_US_DIALOG_STEP_3:
      return (
        <JoinUsStep3Dialogue
          handleClose={closeDialogue}
          currencies={currencies}
          memberships={memberships}
        />
      );
    case MOBILE_PARTNER_MENU_DIALOGUE:
      return <MobileMenuDialoguePartner handleClose={closeDialogue} />;
    case MOBILE_MENU_DIALOGUE:
      return <MobileMenuDialogue handleClose={closeDialogue} />;
    case TERMS_AND_CONDITIONS_DIALOGUE:
      return (
        <TermsAndConditionsDialogue
          handleClose={() => openDialogue(JOIN_US_DIALOG_STEP_2)}
        />
      );
    case NEWSLETTER_SUBSCRIPTION_MODAL:
      return <NewsLetterSubscriptionModal handleClose={closeDialogue} />;
    case COOKIE_CONSENT_MODAL:
      return <CookieConsentBanner handleOpen={openDialogue} />;
    case REGISTRATION_SUCCESS_DIALOGUE:
      return <RegistrationSuccessDialogue handleClose={closeDialogue} />;
    case RESET_PASSWORD_DIALOGUE:
      return <ResetPasswordDialogue handleClose={closeDialogue} />;
    default:
      return null;
  }
};

export const DialoguePresenter: FC<IDialoguePresenter> = ({
  memberships,
  currencies,
  countryCodes,
}) => {
  const { dialogueState, closeDialogue, openDialogue } =
    useContext(DialogueContext);

  return (
    <Suspense fallback={null}>
      {renderDialogue(
        dialogueState,
        openDialogue,
        closeDialogue,
        memberships,
        currencies,
        countryCodes
      )}
    </Suspense>
  );
};

export default DialoguePresenter;
