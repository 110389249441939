import { colors } from './constants';
import { Color, ColorsObject, Margins } from './types';

export const getMarginFromProps = (props: Margins) => {
  const { mt, mb, ml, mr } = props;
  return {
    marginTop: mt,
    marginBottom: mb,
    marginLeft: ml,
    marginRight: mr,
  };
};

export const getColorFromString = (colorString: Color) => {
  const [colorName, shade] = colorString.split('-');

  const color = shade
    ? colors[colorName as keyof ColorsObject][
        shade as keyof ColorsObject['grey' | 'gold' | 'forms']
      ]
    : colors[colorString as keyof ColorsObject];

  return color ? color : colors.black;
};
