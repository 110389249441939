import { FC, SVGProps, useId } from 'react';

interface IWhatsAppIcon extends SVGProps<SVGSVGElement> {
  
}

const WhatsAppIcon: FC<IWhatsAppIcon> = ({...props}) => {
  const iconId = useId();
  const gradientId = useId();

  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath={`url(#${iconId})`}>
        <path d="M34.1083 5.81282C30.3646 2.06655 25.3866 0.00228568 20.0829 0C9.15379 0 0.259706 8.89125 0.25533 19.8199C0.254057 23.3132 1.16683 26.7233 2.90176 29.7291L0.0888672 40L10.5997 37.2439C13.4956 38.8233 16.7562 39.6555 20.0744 39.6568H20.0828H20.0829C31.0102 39.6568 39.9057 30.764 39.9103 19.8359C39.9121 14.5394 37.8515 9.55935 34.1083 5.81282ZM20.0829 36.3092H20.0765C17.1188 36.3076 14.2185 35.5136 11.6887 34.0124L11.0867 33.6555L4.84933 35.2909L6.51461 29.2117L6.12236 28.5885C4.47258 25.9653 3.60164 22.9339 3.60288 19.8211C3.6065 10.7376 10.9989 3.34778 20.0891 3.34778C24.4911 3.34961 28.6284 5.06544 31.74 8.17922C34.8515 11.293 36.5638 15.4323 36.5624 19.8345C36.5586 28.9183 29.1659 36.3092 20.0829 36.3092Z" fill="#E0E0E0"/>
        <path d="M0.999023 38.8605L3.68343 29.0588C2.02732 26.1902 1.15641 22.9357 1.15745 19.6025C1.1618 9.17333 9.64969 0.688477 20.0786 0.688477C25.1403 0.690762 29.891 2.66063 33.4629 6.23587C37.0355 9.81107 39.0019 14.5633 38.9998 19.6173C38.9955 30.0468 30.507 38.5324 20.0794 38.5324C20.0786 38.5324 20.0799 38.5324 20.0794 38.5324H20.071C16.9044 38.5313 13.793 37.7365 11.0295 36.2303L0.999023 38.8605Z" fill={`url(#${gradientId}`} />
        <path fillRule="evenodd" clipRule="evenodd" d="M15.1837 11.4055C14.8169 10.5899 14.4306 10.5736 14.0817 10.5594C13.7962 10.5471 13.4694 10.5479 13.1432 10.5479C12.8166 10.5479 12.286 10.6706 11.8373 11.1606C11.3883 11.6507 10.123 12.8353 10.123 15.2447C10.123 17.6544 11.8781 19.9827 12.1228 20.3097C12.3677 20.6362 15.5107 25.7387 20.4885 27.7017C24.6254 29.3331 25.4672 29.0086 26.3652 28.9269C27.2631 28.8453 29.2626 27.7425 29.6707 26.5989C30.0789 25.4555 30.0789 24.4755 29.9565 24.2706C29.834 24.0665 29.5075 23.9441 29.0177 23.6992C28.5279 23.4543 26.1203 22.2694 25.6713 22.1061C25.2223 21.9428 24.8958 21.8612 24.5693 22.3515C24.2428 22.8413 23.3048 23.9441 23.019 24.2706C22.7333 24.5979 22.4476 24.6387 21.9578 24.3938C21.468 24.1481 19.8908 23.6316 18.0196 21.9632C16.5638 20.6653 15.5809 19.0622 15.2952 18.5719C15.0094 18.0821 15.2645 17.8168 15.5102 17.5727C15.7301 17.3533 16 17.001 16.2449 16.7151C16.4893 16.4291 16.5709 16.225 16.7342 15.8985C16.8974 15.5714 16.8158 15.2855 16.6933 15.0406C16.5709 14.7957 15.6191 12.3739 15.1837 11.4055Z" fill="white"/>
        <path d="M33.9463 5.74978C30.2455 2.04651 25.3247 0.00598045 20.0819 0.00366211C9.27825 0.00366211 0.486262 8.79284 0.481919 19.596C0.480646 23.0493 1.38294 26.4202 3.09795 29.3916L0.317383 39.5445L10.7075 36.82C13.5703 38.3812 16.7934 39.2039 20.0735 39.2052H20.0818H20.0819C30.8838 39.2052 39.6771 30.4145 39.6817 19.6118C39.6835 14.3762 37.6465 9.4533 33.9463 5.74978V5.74978ZM20.0819 35.8961H20.0756C17.1519 35.8945 14.2848 35.1096 11.7841 33.6257L11.1889 33.2729L5.02318 34.8894L6.66933 28.8801L6.28158 28.264C4.65075 25.671 3.78979 22.6743 3.79107 19.5973C3.79463 10.6181 11.1022 3.31307 20.0881 3.31307C24.4395 3.31487 28.5293 5.01101 31.6053 8.08904C34.681 11.1671 36.3736 15.2588 36.3723 19.6106C36.3684 28.59 29.0606 35.8961 20.0819 35.8961Z" fill="white"/>
      </g>
      <defs>
        <linearGradient id={gradientId} x1="19.9994" y1="38.8605" x2="19.9994" y2="0.68854" gradientUnits="userSpaceOnUse">
          <stop stopColor="#20B038"/>
          <stop offset="1" stopColor="#60D66A"/>
        </linearGradient>
        <clipPath id={iconId}>
          <rect width="40" height="40" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}

export default WhatsAppIcon;
