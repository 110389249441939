import { FC, SVGProps } from 'react';
import { colorConstants } from 'data/constants';

interface ILittleEmperorsIcon extends SVGProps<SVGSVGElement> {
  color?:
    | typeof colorConstants.black
    | typeof colorConstants.white
    | typeof colorConstants.brandColor;
}

const LittleEmperorsIcon: FC<ILittleEmperorsIcon> = ({
  color = colorConstants.white,
  ...props
}) => {
  return (
    <svg
      width="170"
      height="20"
      viewBox="0 0 170 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>Little Emperors</title>
      <path
        d="M3.44543 16.45C3.7743 16.45 4.05258 16.337 4.28026 16.111C4.52059 15.8725 4.64708 15.59 4.65973 15.2636V4.83058C4.64708 4.50415 4.52059 4.22795 4.28026 4.00197C4.05258 3.76342 3.7743 3.64415 3.44543 3.64415H3.19877V3.45583H7.35398V3.64415H7.10733C6.65196 3.64415 6.30411 3.83875 6.06378 4.22795C5.94994 4.41627 5.89302 4.61715 5.89302 4.83058V16.0545H8.60624C9.54227 16.0545 10.2569 15.8474 10.7503 15.4331C11.2436 15.0188 11.6547 14.4036 11.9835 13.5875H12.1733L11.0728 16.6383H3.19877V16.45H3.44543Z"
        fill={color}
      />
      <path
        d="M13.8216 16.45C14.1505 16.45 14.4288 16.337 14.6564 16.111C14.8968 15.8725 15.0233 15.59 15.0359 15.2636V4.81175C15.0233 4.48532 14.8968 4.20912 14.6564 3.98313C14.4288 3.75715 14.1505 3.64415 13.8216 3.64415H13.5749V3.45583H17.7302V3.64415H17.4835C17.1546 3.64415 16.87 3.75715 16.6297 3.98313C16.402 4.20912 16.2818 4.48532 16.2692 4.81175V15.2636C16.2692 15.59 16.3894 15.8725 16.6297 16.111C16.87 16.337 17.1546 16.45 17.4835 16.45H17.7302V16.6383H13.5749V16.45H13.8216Z"
        fill={color}
      />
      <path
        d="M30.023 5.13189C30.023 4.83058 29.9154 4.57321 29.7004 4.35978C29.4854 4.13379 29.2324 4.01452 28.9415 4.00197H25.3365V15.2824C25.3491 15.6088 25.4693 15.885 25.697 16.111C25.9373 16.337 26.2219 16.45 26.5508 16.45H26.7974V16.6383H22.6422V16.45H22.8889C23.2178 16.45 23.496 16.337 23.7237 16.111C23.9641 15.885 24.0906 15.6151 24.1032 15.3012V4.00197H20.4982C20.1946 4.01452 19.9353 4.13379 19.7203 4.35978C19.5179 4.57321 19.4167 4.83058 19.4167 5.13189V5.45204L19.227 5.47087V3.09802C19.4167 3.17335 19.7456 3.24868 20.2136 3.32401C20.6816 3.39934 21.1117 3.437 21.5038 3.437H27.9169C28.5367 3.437 29.049 3.39306 29.4538 3.30518C29.8712 3.20474 30.1242 3.13569 30.2127 3.09802V5.47087L30.023 5.45204V5.13189Z"
        fill={color}
      />
      <path
        d="M42.0297 5.13189C42.0297 4.83058 41.9222 4.57321 41.7071 4.35978C41.4921 4.13379 41.2391 4.01452 40.9482 4.00197H37.3432V15.2824C37.3558 15.6088 37.476 15.885 37.7037 16.111C37.944 16.337 38.2286 16.45 38.5575 16.45H38.8042V16.6383H34.649V16.45H34.8956C35.2245 16.45 35.5028 16.337 35.7304 16.111C35.9708 15.885 36.0973 15.6151 36.1099 15.3012V4.00197H32.5049C32.2014 4.01452 31.9421 4.13379 31.727 4.35978C31.5246 4.57321 31.4234 4.83058 31.4234 5.13189V5.45204L31.2337 5.47087V3.09802C31.4234 3.17335 31.7523 3.24868 32.2203 3.32401C32.6883 3.39934 33.1184 3.437 33.5105 3.437H39.9236C40.5434 3.437 41.0557 3.39306 41.4605 3.30518C41.8779 3.20474 42.1309 3.13569 42.2194 3.09802V5.47087L42.0297 5.45204V5.13189Z"
        fill={color}
      />
      <path
        d="M43.9681 16.45C44.297 16.45 44.5752 16.337 44.8029 16.111C45.0433 15.8725 45.1697 15.59 45.1824 15.2636V4.83058C45.1697 4.50415 45.0433 4.22795 44.8029 4.00197C44.5752 3.76342 44.297 3.64415 43.9681 3.64415H43.7214V3.45583H47.8766V3.64415H47.63C47.1746 3.64415 46.8268 3.83875 46.5864 4.22795C46.4726 4.41627 46.4157 4.61715 46.4157 4.83058V16.0545H49.1289C50.0649 16.0545 50.7796 15.8474 51.2729 15.4331C51.7662 15.0188 52.1773 14.4036 52.5062 13.5875H52.6959L51.5955 16.6383H43.7214V16.45H43.9681Z"
        fill={color}
      />
      <path
        d="M54.3443 16.45C54.6731 16.45 54.9514 16.337 55.1791 16.111C55.4194 15.8725 55.5459 15.59 55.5586 15.2636V4.71759C55.5206 4.41627 55.3878 4.16518 55.1601 3.9643C54.9324 3.75087 54.6605 3.64415 54.3443 3.64415H54.1166L54.0976 3.45583H59.6569C60.049 3.45583 60.4727 3.41817 60.9281 3.34284C61.3961 3.26751 61.725 3.19218 61.9147 3.11685V5.4897H61.725V5.15073C61.725 4.84941 61.6175 4.59204 61.4024 4.37861C61.2 4.16518 60.9534 4.05218 60.6625 4.03963H56.7919V9.85876H59.9984C60.2514 9.8462 60.4664 9.75204 60.6435 9.57628C60.8206 9.40051 60.9091 9.1808 60.9091 8.91715V8.6535H61.0989V11.629H60.9091V11.3465C60.9091 11.0954 60.8206 10.882 60.6435 10.7062C60.4791 10.5304 60.2703 10.4363 60.0174 10.4237H56.7919V16.0545H59.5051C60.4411 16.0545 61.1558 15.8474 61.6491 15.4331C62.1424 15.0188 62.5535 14.4036 62.8824 13.5875H63.0721L61.9716 16.6383H54.0976V16.45H54.3443Z"
        fill={color}
      />
      <path
        d="M70.0382 16.45C70.3671 16.45 70.6454 16.337 70.8731 16.111C71.1134 15.8725 71.2399 15.59 71.2525 15.2636V4.71759C71.2146 4.41627 71.0818 4.16518 70.8541 3.9643C70.6264 3.75087 70.3545 3.64415 70.0382 3.64415H69.8105L69.7916 3.45583H75.3508C75.743 3.45583 76.1667 3.41817 76.6221 3.34284C77.0901 3.26751 77.419 3.19218 77.6087 3.11685V5.4897H77.4189V5.15073C77.4189 4.84941 77.3114 4.59204 77.0964 4.37861C76.894 4.16518 76.6474 4.05218 76.3564 4.03963H72.4858V9.85876H75.6924C75.9453 9.8462 76.1604 9.75204 76.3375 9.57628C76.5145 9.40051 76.6031 9.1808 76.6031 8.91715V8.6535H76.7928V11.629H76.6031V11.3465C76.6031 11.0954 76.5145 10.882 76.3375 10.7062C76.173 10.5304 75.9643 10.4363 75.7113 10.4237H72.4858V16.0545H75.199C76.1351 16.0545 76.8497 15.8474 77.3431 15.4331C77.8364 15.0188 78.2475 14.4036 78.5763 13.5875H78.7661L77.6656 16.6383H69.7916V16.45H70.0382Z"
        fill={color}
      />
      <path
        d="M96.6799 16.45H96.9835V16.6383H92.8663V16.4688H93.1509C93.3659 16.4688 93.5493 16.3872 93.7011 16.224C93.8529 16.0483 93.9161 15.8599 93.8908 15.6591L93.037 6.18649L89.0526 14.9811C88.6984 15.7469 88.4834 16.3872 88.4075 16.902H88.2177L83.3605 6.45014L82.5446 15.6591C82.5193 15.8599 82.5826 16.0483 82.7344 16.224C82.8862 16.3872 83.0632 16.4688 83.2656 16.4688H83.5692V16.6383H79.8883V16.45H80.1919C80.5461 16.45 80.8497 16.3433 81.1026 16.1299C81.3683 15.9164 81.5264 15.6277 81.577 15.2636L83 3.19218H83.1897L88.4834 14.548L93.6632 3.19218H93.8339L95.2949 15.2636C95.3581 15.7658 95.6111 16.1173 96.0538 16.3182C96.2435 16.4061 96.4523 16.45 96.6799 16.45Z"
        fill={color}
      />
      <path
        d="M107.261 7.09044C107.261 7.64285 107.147 8.15759 106.919 8.63467C106.502 9.52606 105.768 10.1475 104.718 10.4991C104.352 10.6246 104.035 10.6874 103.77 10.6874C102.985 10.6874 102.309 10.543 101.74 10.2542V10.0659C102.663 10.2668 103.517 10.1664 104.301 9.7646C104.997 9.41307 105.471 8.83555 105.724 8.03204C105.8 7.78095 105.838 7.54241 105.838 7.31642C105.838 7.09044 105.838 6.95233 105.838 6.90211C105.813 6.02328 105.578 5.32649 105.136 4.81175C104.693 4.28445 104.073 4.0208 103.276 4.0208H101.094V15.2636C101.107 15.59 101.227 15.8725 101.455 16.111C101.695 16.337 101.98 16.45 102.309 16.45H102.555V16.6383H98.4002V16.45H98.6468C98.9757 16.45 99.254 16.337 99.4817 16.111C99.722 15.8725 99.8485 15.59 99.8611 15.2636V4.73642C99.8232 4.42255 99.6904 4.16518 99.4627 3.9643C99.235 3.75087 98.963 3.64415 98.6468 3.64415H98.4191L98.4002 3.45583H103.352C104.567 3.45583 105.522 3.78226 106.217 4.4351C106.913 5.0754 107.261 5.96051 107.261 7.09044Z"
        fill={color}
      />
      <path
        d="M109.505 16.45C109.834 16.45 110.112 16.337 110.34 16.111C110.58 15.8725 110.706 15.59 110.719 15.2636V4.71759C110.681 4.41627 110.548 4.16518 110.321 3.9643C110.093 3.75087 109.821 3.64415 109.505 3.64415H109.277L109.258 3.45583H114.817C115.209 3.45583 115.633 3.41817 116.089 3.34284C116.557 3.26751 116.885 3.19218 117.075 3.11685V5.4897H116.885V5.15073C116.885 4.84941 116.778 4.59204 116.563 4.37861C116.361 4.16518 116.114 4.05218 115.823 4.03963H111.952V9.85876H115.159C115.412 9.8462 115.627 9.75204 115.804 9.57628C115.981 9.40051 116.07 9.1808 116.07 8.91715V8.6535H116.259V11.629H116.07V11.3465C116.07 11.0954 115.981 10.882 115.804 10.7062C115.64 10.5304 115.431 10.4363 115.178 10.4237H111.952V16.0545H114.666C115.602 16.0545 116.316 15.8474 116.81 15.4331C117.303 15.0188 117.714 14.4036 118.043 13.5875H118.233L117.132 16.6383H109.258V16.45H109.505Z"
        fill={color}
      />
      <path
        d="M123.932 4.03963H122.718V15.2636C122.718 15.59 122.831 15.8725 123.059 16.111C123.299 16.337 123.59 16.45 123.932 16.45H124.16L124.179 16.6383H120.023V16.45H120.27C120.599 16.45 120.877 16.337 121.105 16.111C121.345 15.885 121.472 15.6088 121.484 15.2824V4.83058C121.472 4.50415 121.345 4.22795 121.105 4.00197C120.877 3.76342 120.599 3.64415 120.27 3.64415H120.023V3.45583H125.146C126.297 3.45583 127.202 3.77598 127.859 4.41627C128.517 5.04401 128.846 5.86635 128.846 6.88328C128.846 7.88766 128.53 8.76022 127.897 9.50095C127.278 10.2291 126.519 10.5932 125.621 10.5932C125.81 10.6811 126.032 10.8569 126.285 11.1205C126.55 11.3716 126.784 11.6353 126.987 11.9115C128.074 13.4557 128.783 14.4099 129.112 14.7739C129.441 15.1255 129.675 15.3703 129.814 15.5084C129.953 15.6339 130.105 15.7595 130.269 15.885C130.434 16.0106 130.598 16.111 130.762 16.1864C131.129 16.3621 131.521 16.45 131.939 16.45V16.6383H130.895C130.351 16.6383 129.845 16.5442 129.377 16.3558C128.909 16.155 128.555 15.9541 128.315 15.7532C128.087 15.5398 127.885 15.3389 127.708 15.1506C127.543 14.9497 127.075 14.2969 126.304 13.192C125.532 12.0872 125.083 11.4595 124.956 11.3088C124.83 11.1456 124.697 10.9887 124.558 10.838C124.153 10.4112 123.749 10.1977 123.344 10.1977V10.0094C123.445 10.022 123.578 10.0282 123.742 10.0282C123.907 10.0282 124.109 10.022 124.349 10.0094C125.918 9.97175 126.904 9.31891 127.309 8.05087C127.385 7.79978 127.423 7.56752 127.423 7.35409C127.423 7.1281 127.423 6.98372 127.423 6.92095C127.398 6.07978 127.176 5.40182 126.759 4.88708C126.342 4.37233 125.779 4.09613 125.07 4.05846C124.767 4.04591 124.387 4.03963 123.932 4.03963Z"
        fill={color}
      />
      <path
        d="M134.08 5.09423C135.345 3.8262 136.989 3.19218 139.013 3.19218C141.037 3.19218 142.681 3.8262 143.946 5.09423C145.211 6.34971 145.843 8.00066 145.843 10.0471C145.843 12.0935 145.211 13.7507 143.946 15.0188C142.681 16.2742 141.037 16.902 139.013 16.902C136.989 16.902 135.345 16.2742 134.08 15.0188C132.827 13.7507 132.201 12.0935 132.201 10.0471C132.201 8.00066 132.827 6.34971 134.08 5.09423ZM135.123 14.6045C136.11 15.7595 137.406 16.337 139.013 16.337C140.632 16.337 141.928 15.7595 142.902 14.6045C143.889 13.4494 144.382 11.9303 144.382 10.0471C144.382 8.80416 144.161 7.70562 143.718 6.75146C143.276 5.7973 142.643 5.06284 141.821 4.5481C141.011 4.0208 140.075 3.75715 139.013 3.75715C137.406 3.75715 136.11 4.33467 135.123 5.4897C134.149 6.64474 133.662 8.16387 133.662 10.0471C133.662 11.9303 134.149 13.4494 135.123 14.6045Z"
        fill={color}
      />
      <path
        d="M151.447 4.03963H150.233V15.2636C150.233 15.59 150.347 15.8725 150.575 16.111C150.815 16.337 151.106 16.45 151.447 16.45H151.675L151.694 16.6383H147.539V16.45H147.785C148.114 16.45 148.393 16.337 148.62 16.111C148.861 15.885 148.987 15.6088 149 15.2824V4.83058C148.987 4.50415 148.861 4.22795 148.62 4.00197C148.393 3.76342 148.114 3.64415 147.785 3.64415H147.539V3.45583H152.662C153.813 3.45583 154.717 3.77598 155.375 4.41627C156.033 5.04401 156.361 5.86635 156.361 6.88328C156.361 7.88766 156.045 8.76022 155.413 9.50095C154.793 10.2291 154.034 10.5932 153.136 10.5932C153.326 10.6811 153.547 10.8569 153.8 11.1205C154.066 11.3716 154.3 11.6353 154.502 11.9115C155.59 13.4557 156.298 14.4099 156.627 14.7739C156.956 15.1255 157.19 15.3703 157.329 15.5084C157.468 15.6339 157.62 15.7595 157.784 15.885C157.949 16.0106 158.113 16.111 158.278 16.1864C158.645 16.3621 159.037 16.45 159.454 16.45V16.6383H158.411C157.867 16.6383 157.361 16.5442 156.893 16.3558C156.425 16.155 156.071 15.9541 155.83 15.7532C155.603 15.5398 155.4 15.3389 155.223 15.1506C155.059 14.9497 154.591 14.2969 153.819 13.192C153.047 12.0872 152.598 11.4595 152.472 11.3088C152.345 11.1456 152.213 10.9887 152.073 10.838C151.669 10.4112 151.264 10.1977 150.859 10.1977V10.0094C150.96 10.022 151.093 10.0282 151.258 10.0282C151.422 10.0282 151.624 10.022 151.865 10.0094C153.433 9.97175 154.42 9.31891 154.825 8.05087C154.901 7.79978 154.938 7.56752 154.938 7.35409C154.938 7.1281 154.938 6.98372 154.938 6.92095C154.913 6.07978 154.692 5.40182 154.274 4.88708C153.857 4.37233 153.294 4.09613 152.586 4.05846C152.282 4.04591 151.903 4.03963 151.447 4.03963Z"
        fill={color}
      />
      <path
        d="M160.028 15.8662C159.99 15.6904 159.971 15.3515 159.971 14.8493C159.971 14.3471 160.066 13.8135 160.255 13.2485H160.426C160.413 13.3866 160.407 13.5247 160.407 13.6629C160.407 14.4287 160.641 15.069 161.109 15.5837C161.577 16.0859 162.197 16.337 162.969 16.337C163.765 16.337 164.417 16.0985 164.923 15.6214C165.441 15.1443 165.701 14.548 165.701 13.8323C165.701 13.1042 165.454 12.5141 164.961 12.0621L161.318 8.71C160.635 8.08226 160.293 7.3227 160.293 6.43131C160.293 5.53992 160.591 4.78036 161.185 4.15262C161.792 3.51233 162.608 3.19218 163.633 3.19218C164.113 3.19218 164.657 3.25496 165.264 3.3805H166.004V5.69686H165.834C165.834 5.10678 165.625 4.63598 165.207 4.28445C164.803 3.93291 164.278 3.75715 163.633 3.75715C162.988 3.75715 162.444 3.97058 162.001 4.39744C161.571 4.8243 161.356 5.35788 161.356 5.99817C161.356 6.63847 161.609 7.19087 162.115 7.6554L165.853 11.0828C166.485 11.6604 166.801 12.4513 166.801 13.4557C166.801 14.4601 166.479 15.2887 165.834 15.9415C165.188 16.5818 164.328 16.902 163.253 16.902C162.697 16.902 162.102 16.8141 161.47 16.6383C160.85 16.4751 160.369 16.2177 160.028 15.8662Z"
        fill={color}
      />
    </svg>
  );
};

export default LittleEmperorsIcon;
