import EnglishTranslation from 'data/english.json';
import { EN } from 'data/constants';
import { LanguageType } from 'types/types';

interface ILocalizationService {
  getTranslationForLanguage(key: string, language: LanguageType): string,
}

class LocalisationService implements ILocalizationService {
  static sharedInstance: ILocalizationService;
  static shared() {
    if (!this.sharedInstance) {
      this.sharedInstance = new LocalisationService();
    }
    return this.sharedInstance;
  }

  public getTranslationForLanguage(key: string, language: LanguageType): string {
    let translation: {[key: string]: string};
    switch (language) {
      case EN:
        translation = EnglishTranslation;
        break;
      default:
        translation = EnglishTranslation;
        break;
    }
    const translatedText = translation[key];
    if (!translatedText) {
      return key;
    }
    return translatedText;
  }
}

export default LocalisationService;
