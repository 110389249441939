import { FC } from 'react';

const FacebookIcon: FC = () => {
  return (
    <svg
      role="img"
      aria-labelledby="facebook-icon-title"
      width="11"
      height="20"
      viewBox="0 0 11 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id="facebook-icon-title">Facebook</title>
      <path
        d="M0.824459 10.652H3.01544V19.6774C3.01544 19.8556 3.15974 20 3.33783 20H7.05272C7.2308 20 7.3751 19.8556 7.3751 19.6774V10.6945H9.89382C10.0576 10.6945 10.1954 10.5715 10.2141 10.4088L10.5966 7.08606C10.6071 6.99465 10.5782 6.9031 10.5171 6.83452C10.4559 6.76587 10.3683 6.72658 10.2764 6.72658H7.37523V4.64374C7.37523 4.01587 7.71309 3.69748 8.37952 3.69748C8.4745 3.69748 10.2764 3.69748 10.2764 3.69748C10.4545 3.69748 10.5988 3.55303 10.5988 3.3749V0.324968C10.5988 0.146774 10.4545 0.0023871 10.2764 0.0023871H7.66222C7.64378 0.00148387 7.60283 0 7.54248 0C7.08889 0 5.51224 0.0890967 4.26681 1.23555C2.88688 2.506 3.07869 4.02716 3.12454 4.2909V6.72652H0.824459C0.646374 6.72652 0.502075 6.8709 0.502075 7.0491V10.3294C0.502075 10.5075 0.646374 10.652 0.824459 10.652Z"
        fill="#BAA57E"
      />
    </svg>
  );
};

export default FacebookIcon;
