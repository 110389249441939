import { FC } from 'react';

const Spinner: FC = () => {
  return (
    <div className="spinner-container">
      <svg className="spinner-container__spinner" width="20" height="20" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M11.0095 0C5.30359 0 0 4.91561 0 11C0.010887 11.5528 0.0466087 12.0904 0.114666 12.5673C0.3199 13.9116 1.0489 14.2264 1.65333 14.1729C1.96071 14.1457 2.23668 14.0028 2.41789 13.7715C2.59024 13.5515 2.69703 13.2464 2.62813 12.7967C2.6137 12.7344 2.60838 12.665 2.59948 12.6056C2.49963 12.0901 2.42745 11.5532 2.42745 11C2.42745 6.27009 6.28064 2.43702 11.0095 2.43702C15.7284 2.43702 19.5725 6.27009 19.5725 11C19.5725 15.7199 15.7294 19.563 11.0095 19.563C9.94486 19.563 8.93555 19.3728 8.00868 19.0278C6.48544 18.3831 5.49341 20.4434 7.04344 21.2642C8.25074 21.8348 9.81147 21.9914 11.0095 22C17.0832 22 22 17.0844 22 11C21.8955 4.62382 16.4385 0.0902439 11.0095 0Z" fill="#191919"/>
      </svg>
    </div>
  );
};

export default Spinner;
