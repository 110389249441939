import { FC, useContext } from 'react';
import useLocalised from 'customHooks/useLocalised/useLocalised';
import {
  navigationPaths,
  externalNavigationPaths,
  phoneNumbers,
  externalLinkTitles,
  dateAndCompany,
  membershipCategoryOptions,
} from 'data/constants';
import {
  contact_us,
  our_company,
  become_a_member,
  about,
  the_team,
  careers,
  press,
  membership,
  corporate,
  our_hotels,
  faq,
  terms_and_conditions,
  privacy_policy,
} from 'data/translationKeys';
import NavigationItem from 'components/NavigationItem/NavigationItem';
import InstagramIcon from 'components/icons/InstagramIcon';
import FacebookIcon from 'components/icons/FacebookIcon';
import TwitterIcon from 'components/icons/TwitterIcon';
import LittleEmperorsIcon from 'components/icons/LittleEmperorsIcon';
import WhatsAppIcon from 'components/icons/WhatsAppIcon';
import Link from 'next/link';
import { JoinUsContext } from 'customHooks/useJoinUs/useJoinUs';
import NewsLetterSubscriptionFooter from 'components/NewsLetterSubscriptionFooter/NewsLetterSubscriptionFooter';

interface IFooter {}

export const Footer: FC<IFooter> = () => {
  const contact_us_text = useLocalised(contact_us);
  const our_company_text = useLocalised(our_company);
  const become_a_member_text = useLocalised(become_a_member);
  const about_text = useLocalised(about);
  const the_team_text = useLocalised(the_team);
  const careers_text = useLocalised(careers);
  const press_text = useLocalised(press);
  const membership_text = useLocalised(membership);
  const corporate_text = useLocalised(corporate);
  const our_hotels_text = useLocalised(our_hotels);
  const faq_text = useLocalised(faq);
  const terms_and_conditions_text = useLocalised(terms_and_conditions);
  const privacy_policy_text = useLocalised(privacy_policy);

  const { joinUsState: state, setState } = useContext(JoinUsContext);

  return (
    <div className="footer-component">
      <div className="footer-component__container">
        <div className="footer-engage">
          <div className="footer-engage__newsletter">
            <NewsLetterSubscriptionFooter />
          </div>
          <div className="footer-engage__social">
            <a
              href={externalNavigationPaths.INSTAGRAM}
              rel="noreferrer"
              target="_blank"
              className="footer-engage__social__logo"
              title={externalLinkTitles.INSTAGRAM_TITLE}
            >
              <InstagramIcon />
            </a>
            <a
              href={externalNavigationPaths.FACEBOOK}
              rel="noreferrer"
              target="_blank"
              className="footer-engage__social__logo"
              title={externalLinkTitles.FACEBOOK_TITLE}
            >
              <FacebookIcon />
            </a>
            <a
              href={externalNavigationPaths.TWITTER}
              rel="noreferrer"
              target="_blank"
              className="footer-engage__social__logo"
              title={externalLinkTitles.TWITTER_TITLE}
            >
              <TwitterIcon />
            </a>
          </div>
        </div>

        <div className="footer-top">
          <div className="footer-top__item">
            <div className="footer-top__item__title">About</div>
            <NavigationItem
              title="Our hotels"
              path={navigationPaths.OUR_HOTELS}
              className="footer-top__item__link"
            />
            <NavigationItem
              title="The team"
              path={navigationPaths.THE_TEAM}
              className="footer-top__item__link"
            />
            <NavigationItem
              title={careers_text}
              path={navigationPaths.CAREERS}
              className="footer-top__item__link"
            />
            <NavigationItem
              title="Partner with us"
              path={navigationPaths.PARTNER_WITH_US}
              className="footer-top__item__link"
            />
            <NavigationItem
              title={press_text}
              path={navigationPaths.PRESS}
              className="footer-top__item__link"
            />
          </div>
          <div className="footer-top__item">
            <div className="footer-top__item__title">
              {become_a_member_text}
            </div>
            <Link className="navigation-item" href={navigationPaths.MEMBERSHIP}>
              <a
                className="footer-top__item__link"
                area-label={membership_text}
                onClick={() => {
                  setState(state => ({
                    ...state,
                    memberShipCategory: membershipCategoryOptions.LEISURE,
                  }));
                }}
              >
                {membership_text}
              </a>
            </Link>

            <NavigationItem
              title={faq_text}
              path={navigationPaths.FAQ}
              className="footer-top__item__link"
            />
          </div>
          <div className="footer-top__item">
            <div className="footer-top__item__title">Gift cards</div>
            <NavigationItem
              title="Buy an e-gift card"
              path={navigationPaths.E_GIFT_CARD}
              className="footer-top__item__link"
            />
            <NavigationItem
              title="Redeem your e-gift card"
              path={navigationPaths.E_GIFT_CARD_REDEEM_INTERNAL}
              className="footer-top__item__link"
            />
          </div>
          <div className="footer-top__item">
            <div className="footer-top__item__title">
              Independent Travel Agent
            </div>
            <NavigationItem
              title="About MyLER"
              path={navigationPaths.DISCOVER_MYLER}
              className="footer-top__item__link"
            />
            <NavigationItem
              title="Enquire"
              path={navigationPaths.DISCOVER_MYLER_ENQUIRE}
              className="footer-top__item__link"
            />
          </div>

          <div className="footer-top__item">
            <div className="footer-top__item__title">Travel Journal</div>
            <NavigationItem
              title="Destination"
              path={navigationPaths.TRAVEL_JOURNAL_ARTICLES}
              className="footer-top__item__link"
            />
            <NavigationItem
              title="Wellness"
              path={navigationPaths.TRAVEL_JOURNAL_ARTICLES}
              className="footer-top__item__link"
            />
            <NavigationItem
              title="Activities"
              path={navigationPaths.TRAVEL_JOURNAL_ARTICLES}
              className="footer-top__item__link"
            />
          </div>

          <div className="footer-top__item">
            <div className="footer-top__item__title">{contact_us_text}</div>
            <div className="footer-top__item__phone">
              <a
                className="footer-phone-number"
                href={`tel:${phoneNumbers.UK}`}
              >
                UK: {phoneNumbers.UK}
              </a>
            </div>
            <div className="footer-top__item__phone">
              <a
                className="footer-phone-number"
                href={`tel:${phoneNumbers.FR}`}
              >
                FR: {phoneNumbers.FR}
              </a>
            </div>
            <div className="footer-top__item__phone">
              <a
                className="footer-phone-number"
                href={`tel:${phoneNumbers.US}`}
              >
                US: {phoneNumbers.US}
              </a>
            </div>
            <div className="footer-top__item__phone">
              <a
                className="footer-phone-number"
                href={`tel:${phoneNumbers.AU}`}
              >
                AU: {phoneNumbers.AU}
              </a>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="footer-bottom__item footer-bottom__item__company">
            <div className="footer-bottom__item__company-logo">
              <LittleEmperorsIcon />
            </div>
            <div className="footer-bottom__item__company-name">
              {dateAndCompany}
            </div>
          </div>
          <div className="footer-bottom__item footer-bottom__item__externals">
            <NavigationItem
              title={terms_and_conditions_text}
              path={navigationPaths.TERMS_AND_CONDITIONS}
              className="footer-bottom__item__link"
            />
            <NavigationItem
              title={privacy_policy_text}
              path={navigationPaths.PRIVACY_POLICY}
              className="footer-bottom__item__link"
            />
            <a
              href={externalNavigationPaths.WHATSAPP}
              rel="noreferrer"
              target="_blank"
              className="footer-bottom__item__whatsapp"
              title={externalLinkTitles.WHATSAPP_TITLE}
            >
              <WhatsAppIcon />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
