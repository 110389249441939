import LocalisationService from 'services/LocalisationService/LocalisationService';
import { EN } from 'data/constants';

const useLocalised = (key: string): string => {
  const translatedText = LocalisationService.shared().getTranslationForLanguage(
    key,
    EN
  );
  return translatedText;
};

type LocalisedTexts<T extends string> = {
  [key in T]: string;
};

export const localise = <T extends string>(keys: T[]): LocalisedTexts<T> => {
  const localisedTexts: LocalisedTexts<T> = {} as LocalisedTexts<T>;
  keys.forEach(key => {
    localisedTexts[key] = useLocalised(key);
  });

  return localisedTexts;
};

export default useLocalised;
