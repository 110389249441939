import { FC } from 'react';
import Link from 'next/link';

interface INavigationItem {
  title: string,
  path: string,
  className: string,
};

const NavigationItem: FC<INavigationItem> = ({
  title,
  path,
  className,
}) => {
  return (
    <Link className="navigation-item" href={path}>
      <a className={className} area-label={title}>
        {title}
      </a>
    </Link>
  );
};

export default NavigationItem;
